import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import { Section } from '../../components/Section';
import { Calculator, CalculatorAside } from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import SectionTop from '../../components/SectionTop';
import staticData from '../index-data.json';
import './index.scss';

const CheapEssay = () => (
  <Layout className="IndexPage CheapEssay">
    <Helmet>
      <title>Cheap Essay Writing Service: Canada Origin | Ca.CustomWritings.com</title>
      <meta
        name="description"
        content="Want to buy cheap essay, but have no idea where to turn? Our service is here for you. Our professionals work 24/7 to provide you with high-quality assistance with every kind of academic writing."
      />
      <link rel="canonical" href="https://ca.customwritings.com/cheap-essay.html" />
      <link
        rel="alternate"
        hrefLang="en"
        href="https://www.customwritings.com/cheap-essays.html"
      />
      <link rel="alternate" hrefLang="en-ca" href="https://ca.customwritings.com/cheap-essay.html" />
      <link rel="alternate" hrefLang="x-default" href="https://www.customwritings.com/cheap-essays.html" />
    </Helmet>

    <SectionTop>
      <h1 className="Section-top__content__title">Buy a cheap essay of the best quality</h1>
      <span className="Section-top__content__subtitle" />
      <span className="Section-top__content__description">Confidentiality, authenticity, and 24/7 support are guaranteed!</span>
      <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button">Buy an essay</a>
    </SectionTop>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-1">Need a cheap essay writing service? You’ve found it!</h2>
      <p>
        Writing constitutes the majority of the work you’re going to do throughout college. You can’t afford to just
        be good at your subject—you should also be able to prove your knowledge and understanding by expressing your
        thoughts in essays, term papers, case studies, and whatnot. However, if you consider the amount of writing a
        typical student has to do in an average course, it becomes obvious that it’s almost impossible to do it all on
        your own—at least if you want to pay close attention to each task. Many students make the natural decision and
        look for a cheap essay writing service to get some assistance with particularly obnoxious assignments.
        However, if you are looking for a cheap essay writing service, Canada isn’t the most convenient country to be
        studying in. You can’t just choose a random service and say, “I need help and I want it to be cheap, write my
        essay, please!” The majority of online services selling cheap essay writing openly or implicitly specialize in
        working with American students, which means using American English as well following the American standards of
        academic writing, approaches to formatting, and other conventions. Of course, you can buy cheap essay writing
        from one of them, but don’t be surprised if the paper you receive requires some significant reworking before
        you can use it. If you don’t want it to be more trouble than it is worth, stick to cheap custom essay writing
        services that specialize in helping Canadian students—like CustomWritings.com, for example.
      </p>
    </Section>

    <Section className="Section-calculator Section--bg-blue">
      <h2 className="Section__heading">Calculate the price of your paper here</h2>
      <div className="calculator-wrapper">
        <Calculator />
        <CalculatorAside />
      </div>
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-2">Cheap essay writing for anyone, at any time</h2>
      <p>
        Our service isn’t just a place where you can buy essay cheap; the price of our assistance is far from being
        the only advantage we offer. Here are a few other things that set us apart from other cheap essay writing
        services:
      </p>
      <ul>
        <li>
          Complete confidentiality. To order a cheap essay from CustomWritings.com, you have to provide the bare
          minimum of information about yourself, and what little of it we receive is transferred using a secure
          encrypted connection.
        </li>
        <li>
          Absence of plagiarism. When you buy essay online cheap, it’s only natural to worry about its originality.
          That’s never an issue with CustomWritings.com, as our low prices result from a workflow perfected after
          years of operating in the academic assistance industry. To make sure every essay is written from scratch, we
          check each of them using advanced anti-plagiarism software.
        </li>
        <li>
          Skilled yet cheap essay writers. We’re able to provide essay writing service cheaply, even though the
          quality of our texts is well above the industry’s average. When you hire us, you don’t hire a cheap essay
          writer—you hire a service that saves costs in other areas and pays fair wages to its employees. We may not
          be the best cheap essay writing service, but we certainly offer one of the best price/quality ratios!
        </li>
      </ul>
      <h2 className="Section__heading Section__heading--bubble-3">It’s easy to get a cheap essay from us</h2>
      <p>
        If you want to order essay online cheap, you have to fill in the order form and provide the details of your
        assignment. If you experience any difficulties in completing your work, you can simply contact our customer
        support and say, “Write my essay for me cheap.” One of our managers will then lead you through the ordering
        process, making sure you provide all the necessary information. You can get an essay writer cheap at any time.
        Our customer support team works 24/7, and we have cheap essay writers from different time zones, which means
        that some of them will be available at any given time.
      </p>
    </Section>

    <Section className="Section Section-testimonials Section--bg-blue">
      <h2 className="Section__heading">What customers say about us</h2>
      <Testimonials testimonials={staticData.testimonials} />
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-1">Our cheap custom essay writing services are reliable</h2>
      <p>
        When students visit an academic assistance service and say, “Write my essay for cheap,” they tend to be a bit
        wary, because low prices aren’t usually associated with reliability. This isn’t the case with
        CustomWritings.com. To ensure your peace of mind, we’ve introduced a number of guarantees:
      </p>
      <ul>
        <li>
          Free revisions. When you come to us and say, “Write my essay cheap”, we commit ourselves to writing a paper
          strictly according to your requirements. If you think that we’ve failed to do so, you have 7 days to ask us
          to revise it for free. As long as you don’t contradict your original instructions, we’re ready to do as many
          revisions as necessary for you to get the best essay cheap.
        </li>
        <li>
          Money-back. Even when you buy cheap essay online, it’s good to know that your money will be safe. We promise
          that if something goes wrong with your paper, we’ll provide you with a full or partial refund, depending on
          the situation.
        </li>
        <li>
          Round-the-clock support. We stay with you throughout the writing process—feel free to contact us for
          clarification and updates on your writer’s progress at any time.
        </li>
      </ul>
      <h2 className="Section__heading Section__heading--bubble-2">Cheap essay writers you can trust</h2>
      <p>
        CustomWritings.com only hires writers with significant experience with working on academic papers. Before
        becoming our employees, they have to pass a number of tests to prove their qualifications. As a result, when
        you come to us and say, “Do my essay cheap,” you can always rest assured the job will be done by somebody who
        knows his/her business. More importantly, we don’t just assign a random person to work on your task. Instead,
        we go through our entire database of writers and pick the best specialist in the relevant type of work. So
        don’t wait any longer—contact us, say, “Write essay for me cheap” and let us help you!
      </p>
    </Section>
  </Layout>
);

export default CheapEssay;
